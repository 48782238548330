<template>
  <div class="task-list-item" @click="editTask">
    <span>
      <img :src="require(`@/assets/icons/${icon}.svg`)" :title='title' height="25"> {{ description }}
    </span>
  </div>
</template>

<script>
import { getIcon } from '@/utils'

export default {
  name: 'TaskLine',
  props: {
    id: String,
    description: String,
    type: String,
    status: String
  },
  data () {
    return {
      icon: '',
      title: '',
      bucket: this.$router.currentRoute.meta.bucket
    }
  },
  updated () {
    this.getIconData()
  },
  beforeMount () {
    this.getIconData()
  },
  methods: {
    getTaskId () {
      return this.id
    },
    getIconData () {
      const { type, status } = this
      const result = getIcon(type, status)
      this.icon = result.icon
      this.title = result.title
    },
    editTask () {
      const { id, description, type, status, bucket } = this
      this.$router.push({ name: 'edit', params: { id, description, type, status, bucket } })
    }
  }
}
</script>

<style lang="scss" scoped>
.task-list-item {
  margin-bottom: 5px;
  padding: 6px;
  // background-color: rgba(black, 0.1);
  background-color: var(--ligther);

  display: flex;
  justify-content: space-between;

  border-left: 4px solid var(--light-medium);

  &:hover {
    border-left: 4px solid var(--featured);
    cursor: pointer;
    font-weight: bold;
    color: var(--featured-dark);
  }

  img {
    margin: 6px;
  }
}
</style>
