<template>
  <div class="row">
    <div class="col">
      <h1 class="page-title">{{ title }}</h1>

      <div class="card">
        <div class="card-body">
          <div class="card-functions text-right">
            <router-link class="btn btn-primary btn-sm" :to="{ name: 'new', params: { bucket } }">
              <i class="fas fa-plus"></i> Nova tarefa
            </router-link>
          </div>
          <task-line
            v-for='(item, i) in tasks'
            :key='i'
            :id='item.id'
            :description='item.description'
            :type='item.type'
            :status='item.status'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskLine from '@/components/layout/TaskLine'

export default {
  components: {
    TaskLine
  },
  data () {
    return {
      title: this.$router.currentRoute.meta.title,
      bucket: this.$router.currentRoute.meta.bucket,
      tasks: []
    }
  },
  created () {
    this.getData()
  },
  methods: {
    showNavigation () {
      this.$root.$emit('Navigation::show')
    },
    async getData () {
      const db = this.$firebase.firestore()
      const tasks = await db.collection('customers').doc(window.uid).collection('tasks').orderBy('cd', 'asc')

      tasks.onSnapshot(task => {
        const { docs } = task
        this.tasks = docs.map(doc => {
          const { d, t, s, b } = doc.data()
          return {
            id: doc.id,
            description: d,
            type: t,
            status: s,
            bucket: b
          }
        }).filter(task => task.bucket === this.bucket)
      }, err => {
        this.$root.$emit('Notification::show', { message: `Error getting documents: ${err}` })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card-functions {
  padding-bottom: 20px;
}
</style>
